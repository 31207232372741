@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700&display=swap");

// Config
@import "app/config/normalize";
@import "app/config/variables";
@import "app/config/mixins";

// Layout
@import "app/layout/base";
@import "app/layout/adjustment";

// Other
@import "telman/app/drawer";
@import "telman/app/toolbar";
@import "telman/app/index-page";
@import "telman/app/icons";
@import "telman/app/ui/slider";
@import "telman/app/ui/slider-cert";
@import "app/ui/spinner";

@import "app/ui/backdrop";
@import "app/ui/modalFullScreen";

// SECTIONS
@import "telman/app/sections/main";
@import "telman/app/sections/about";
@import "telman/app/sections/portfolio";
@import "telman/app/sections/services";
@import "telman/app/sections/achievement";
@import "telman/app/sections/sport";

//RESPONSIVE
@import "telman/app/responsive";


// UI
//@import "app/ui/button";
//@import "app/ui/spinner";
//@import "app/ui/button-def";
//@import "app/ui/form-def";
//@import "app/ui/form-login";
//@import "app/ui/modal";
//@import "app/ui/error";
//@import "app/ui/avatar";
//@import "app/ui/form-calendar";
//@import "app/ui/model-left";
//@import "app/ui/simple-table";
//@import "app/ui/w-closed";
//@import "app/ui/pagination";
//@import "app/ui/regular-link";
//@import "app/ui/custom-scrollbar";
//@import "app/ui/footer";
//@import "app/ui/jumbotron";

// Component
//@import "app/component/header_guest";
//@import "app/component/header_admin";
//@import "app/component/offer";
//@import "app/component/login";
//@import "app/component/menu_left_admin";
//@import "app/component/admin/order/order";
//@import "app/component/admin/product/_select_custom_search.scss";
//@import "app/component/admin/product/_select_custom_tree.scss";
//@import "app/component/admin/product/custom_select.scss";
//@import "app/component/admin/product/custom_input.scss";
//@import "app/component/admin/product/editor_wrapper.scss";
//@import "app/component/admin/product/dropzone.scss";
//@import "app/component/dimmer";
//@import "app/component/table";
//@import "app/component/admin/product/error.scss";
//@import "app/component/map-wrapper";
//@import "app/component/custom_map";
//@import "app/component/check_day";
//@import "app/component/custom_legend";
//@import "app/component/select-popup";
//@import "app/component/success-badge";

// Sections
//@import "app/sections/main";
//@import "app/sections/register";
//@import "app/sections/welcome";
//@import "app/sections/admin/section";
//@import "app/sections/admin/dashboard";
//@import "app/sections/admin/products";
//@import "app/sections/admin/categories";
//@import "app/sections/admin/brands";
//@import "app/sections/admin/promotion";
//@import "app/sections/admin/create_promotion";
//@import "app/sections/admin/premium_brands";
//@import "app/sections/admin/banner";
//@import "app/sections/admin/customer";
//@import "app/sections/admin/edit_customer";
//@import "app/sections/admin/sub_customer";
//@import "app/sections/admin/regions";
//@import "app/sections/admin/create_region";
//@import "app/sections/admin/delivery_type";
//@import "app/sections/admin/statistics";
//@import "app/sections/admin/orders";
//@import "app/sections/admin/dialog";
//@import "app/sections/admin/graph";

//@import "app/sections/_index.scss";
//@import "app/sections/producer/view-order";
//@import "app/sections/producer/command";
//@import "app/sections/producer/edit-manager-page";
//@import "app/ui/slider";
