.Inner-section {
  width: 85%;
  margin: 0 auto;
  max-width: 120rem;
  height: 100%;
  position: relative;

  .section-heading {
    margin: 8rem 0 5rem 0;
    //margin-top: 10rem;
    text-align: center;

    h1 {
      font-size: 3.5rem;
      text-transform: uppercase;
      color: rgba(255, 255, 255, .3);
      font-weight: 300;
      position: relative;
      margin-bottom: 1rem;

      &:after,
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.5rem;
        //background-color: rgba(255,255,255, 0.75);
      }

      &:after {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        bottom: -0.9rem;
        background: linear-gradient(to top, #ffe838, #fe4f6c);
      }

      &:before {
        width: 10rem;
        height: 3px;
        border-radius: 0.8rem;
        background: linear-gradient(to right, #ffe838, #fe4f6c);
      }
    }

    h6 {
      font-size: 1.3rem;
      font-weight: 300;
    }
  }
}