.Drawer {
  position: absolute;
  z-index: 3;
  top: 32px;
  right: 32px;
  cursor: pointer;
  @include easeOut;

  &__line {
    width: 28px;
    height: 3px;
    margin: 0 0 5px 0;
    //background-color: set-text-color($primary-color);
    background: linear-gradient(to right, #ffe838, #fe4f6c);
    border-radius: 0.8rem;
    @include easeOut;
  }

  &.close {
    transform: rotate(180deg);

    .Drawer__line {
      &:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -6px);
      }
    }
  }


}