@mixin circle($width, $color) {
  width: $width;
  height: $width;
  border-radius: $width/2;
  background-color: $color;
}

@mixin easeOut {
  transition: all 0.5s ease-out;
}

@mixin background-image {
  @if $show-home-image {
    &#bg-img {
      background-image: $home-image;
      background-attachment: fixed;
      background-size: cover;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgba($primary-color, $background-opacity);
      }
    }
  }
}

@function set-text-color($color) {
  @if(lightness($color) > 40) {
    @return #444;
  } @else {
    @return #eee;
  }
}

@function rem($px) {
  @return $px / 16px+0rem;
}

@mixin large {
  @media screen and (min-width: 1171px) {
    @content
  }
}

@mixin desktops {
  @media screen and (min-width: 769px) and (max-width: 1170px) {
    @content
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content
  }
}

@mixin phones {
  @media screen and (max-width: 500px) {
    @content
  }
}