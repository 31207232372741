*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'PT Sans', sans-serif;
  //font-family: 'Montserrat', sans-serif;
  //font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  color: #e3eaef;
  background-color: #3a3973;

  font-size: rem(16px);
  line-height: 1.5;
  height: 100%;
  width: 100%;
  //@include background-image;
  //background: url("/assets/sitebg.jpg") no-repeat center fixed;
  //background-size: cover;

  @include tablets() {
    font-size: 12px;
  }

  @include phones() {
    font-size: 13px;
  }

  &.blocked {
    overflow: hidden;
  }
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

body {
  &.modal-open {
    overflow: hidden !important;
  }
}



ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

h1, h2, h3 {
  margin: 0;
  font-weight: 400;

  &.lg-heading {
    font-size: 6rem;
  }

  &.sm-heading {
    margin-bottom: 2rem;
    padding: 0.2rem 1rem;
    background: rgba(lighten($primary-color, 2), 0.5);
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15) !important;
  }
}

a {
  color: #fff;
  text-decoration: none;
}

header {
  position: fixed;
  z-index: 2;
  width: 100%;
}
