.Toolbar {
  position: fixed;
  top: 0;
  width: 100%;
  opacity: 1;
  visibility: hidden;

  &.show {
    visibility: visible;
  }

  &__branding,
  &__list {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100vh;
    float: left;
    overflow: hidden;
  }

  &__portrait {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 3px solid $secondary-color;
    background-image: url("/assets/avatar.png");
    background-size: cover;
    background-position: center;
    position: relative;
  }

  &__img {
    position: absolute;
    left: -9999px;
  }

  &__branding {
    background-color: $primary-color-light;
    @include easeOut;
    transform: translate3d(0, 100%, 0);

    &.show {
      transform: translate3d(0, 0, 0);
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: darken($primary-color-light, 5);
    @include easeOut;
    transform: translate3d(0, -100%, 0);

    &.show {
      transform: translate3d(0, 0, 0);
    }
  }

  &__item {
    @include easeOut;
    transform: translate3d(600px, 0, 0);

    &.show {
      transform: translate3d(0, 0, 0);
    }
  }

  &__link {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    font-size: 30px;
    position: relative;
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1px 0;
    color: set-text-color($primary-color);
    @include easeOut;

    &:hover,
    &:focus,
    &--active {
      //background: linear-gradient(to top,  #fd57bf, #ffe838);
      //-webkit-background-clip: text;
      //-webkit-text-fill-color: transparent;

      color: $secondary-color;
    }
  }
}

// Delay each nav item slide by 0.1s
@for $x from 1 through 4 {
  .Toolbar__item:nth-child(#{$x}) {
    transition-delay: $x * 0.1s;
  }
}