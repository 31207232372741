.Icons {
  margin-top: 1rem;

  a {
    padding: 0.4rem;

    &:hover {
      color: $secondary-color;

      @include easeOut();
    }
  }
}