.Services {

  &__skills {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    grid-gap: 2.5rem;
    text-align: center;
  }

  &__skill {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 1rem;
      text-transform: uppercase;
      margin: 2rem -2px 2rem 0;
      letter-spacing: 2px;
    }
  }

  &__icon-container {
    width: 6rem;
    height: 6rem;
    transform: rotate(45deg);
    display: flex;
    border: 3px solid #ffe838;
    background-color: #fe4f6c;
    margin-bottom: 1rem;
    @include easeOut;

    &:hover {
      transform: initial;
    }
  }

  &__fa {
    font-size: 3rem;
    margin: auto;
    color: #ffe838;
    transform: rotate(-45deg);
  }

}