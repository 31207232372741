$primary-color: #16162d;
$primary-color-light: #3a3973;
$secondary-color: #eece1a;
$ev-green-one: #409695;
$orange: #e25028;
$yellow: #f8b43a;
$green: #8ac03e;
$unique-color: #3f729b;
$info-color-dark: #0099cc;
$info-color: #33b5e5;
$info-color-light: #f9fdff;
$success-color: #00c851;
$success-color-alpha: #00c851;
$danger-color: #ff4444;
$elegant-color: #2e2e2e;
$elegant-color-dark: #212121;
$unique-color-dark: #1c2331;
$stylish-color-dark: #3e4551;
$stylish-color: #4b515d;
$stylish-color-light: rgb(127, 132, 143);
$special-color-dark: #263238;
$special-color: #37474f;
$rgba-indigo-strong: rgba(63, 81, 181, 0.7);
//параметры
$show-home-image: true;
$home-image: url("/assets/dev-screen.jpg");
$background-opacity: 0.9;
//экраны
$large-screen: 1171px;
$desktops: 1200px;
$tablets: 768px;
$phones: 480px;
$small: 320px;
//шрифты
$fontDesktops: 16px;
$fontTablets: 15px;
$fontPhones: 13px;