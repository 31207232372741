.Slider {
  //background-color: #3dcc4a;
  width: 80%;
  height: 90%;
  margin: 20px auto;

  .slick-arrow::before {
    color: rgb(212, 212, 212);
  }

  .slick-prev:before, .slick-next:before {
    font-size: rem(30px);
  }

  &__item {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 600px;

    img {
      position: absolute;
      left: -9999px;
      //display: block;
      //height: 100%;
      //width: 100%;
      // border-top-left-radius: 30px;
      // border-bottom-left-radius: 30px;
      //object-fit: scale-down;
      //background: rgba($ev-green-one, 0.3);
    }
  }
}