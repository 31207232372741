.Slider-cert {
  width: 1050px;
  margin: 0 auto;


  .slick-arrow::before {
    color: rgb(212, 212, 212);
  }

  .slick-prev:before, .slick-next:before {
    font-size: rem(30px);
  }

  &__item {
    //background-color: #1d5d90;
    //display: flex;
    //flex-direction: column;
    //width: 350px;
    width: 350px;
    height: 500px;
    padding: 10px;
    margin-left: 1px;
  }

  .card {
    display: flex;
    flex-direction: column;


    &-header {
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: rem(200px);

      &__img {
        position: absolute;
        left: -9999px;
        height: 1px;
        width: 1px;
      }
    }

    &-content {
      //margin-top: rem(20px);

      box-shadow: 0 1px 10px rgba(153, 133, 255, 0.2);
      padding: 18px 30px;

      &__title {
        font-size: rem(20px);
        font-weight: bold;
        margin-bottom: rem(20px);
      }

      &__desc {
        height: rem(90px);
        margin-bottom: rem(20px);
      }

      &__options {
        display: flex;
        justify-content: center;
      }

      &__link {
        margin: 20px 0px 0px 0px;
        background: #fe4f6c;
        border-color: #fe4f6c;
        font-size: 14px;
        padding: 10px 30px;
        cursor: pointer;
        font-weight: 400;
        color: #fff;
        border-radius: 30px;
        text-transform: uppercase;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        display: inline-block;

        &:hover {
          box-shadow: 1px 5px 12px rgba(0,0,0, 0.15);
        }
      }
    }
  }

}
