.Sport {

  &__inner {
    margin-top: rem(60px);
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item {
    //margin: 0 rem(10px) rem(10px) 0;
    //flex-basis: 45%;
    margin-bottom: rem(30px);
    //background-color: #ccc;
  }

  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //align-items: center;

    &-header {
      //position: relative;
      //z-index: 1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      //width: 80%;
      flex-basis: 47%;
      height: 350px;
      //box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
      //border-radius: .25rem;

      &__img {
        position: absolute;
        left: -9999px;
        height: 1px;
        width: 1px;
      }
    }


    &-content {
      //position: relative;
      //margin-top: -2rem;
      //border-radius: .25rem;
      //box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
      //z-index: 1;
      //width: 90%;
      flex-basis: 47%;
      //height: 300px;
      //background-color: #3a3973;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: rem(25px) rem(15px);

      &__title {

      }

      &__desc {
        //height: rem(60px);
        font-size: 1.25rem;
        line-height: rem(24px);
        font-weight: bold;
        margin-bottom: 1.25rem;
        text-align: center;
      }

      &__achievement {

      }
    }

    &-resources {
      margin-top: rem(30px);
      width: 100%;

      &__list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }

      &__item {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;

        &:hover {
          svg {
            color: #fe4f6c;
            top: -5px;
          }
        }
      }

      &__href {
        display: inline-block;

      }

      &__icon {
        color: #ffe838;
        position: relative;
        top: 0;
        transition: all 0.2s ease-in;
        width: rem(40px);
        height: rem(40px);
      }
    }
  }

  &__modal {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/assets/spinner_2.gif");
    background-size: 30px 30px;
  }
}
