@include large {

}

@include desktops {

  .Portfolio {
    &__company {
      &-desc {
        height: 7rem;
      }
    }
  }

  .Slider-cert {
    width: 800px;

    &__item {
      width: 400px;
    }
  }

}

@include tablets {
  .Main {
    align-items: center;
    text-align: center;

    .lg-heading {
      line-height: 1;
      margin-bottom: 1rem;
      font-size: 4rem;
    }
  }

  .Toolbar {
    &__branding,
    &__list {
      width: 100%;
      float: none;
      min-height: 0;

      &.show {
        transform: translate3d(0, 0, 0);
      }
    }

    &__branding {
      height: 25vh;
      transform: translate3d(100%, 0, 0);
    }

    &__list {
      font-size: 24px;
      height: 75vh;
      transform: translate3d(-100%, 0, 0);
    }

    &__portrait {
      height: 150px;
      width: 150px;
    }
  }

  .Modal-Full-Screen {
    padding: initial;
  }

  .Sport {
    .card {
      &-header {
        flex-basis: 60%;
        height: 300px;
      }

      &-content {
        flex-basis: 40%;
        padding: rem(15px) rem(15px);
      }

      &__desc {

      }
    }
  }
}

@include phones {

  .Inner-section {
    .section-heading {
      h1 {
        font-size: 2.5rem;
      }
    }
  }
  .Main {
    .lg-heading {
      font-size: 3rem;
    }
  }

  .About {
    &__heading {
      h1 {
        font-size: 4.5rem;
      }
    }
    &__inner {
      width: 93%;
      flex-direction: column;
    }

    &__portrait {
      margin-bottom: rem(20px);
    }

    &__pick {
      background-size: cover;
      height: 230px;
    }

    &__item  {
      margin: 0 0 rem(20px) 0;
    }

    &__item-inner {
      margin-right: 1rem;
      width: 73px;
      height: 73px;
    }


  }

  .Portfolio {
    .Inner-section {
      width: 93%;

      .section-heading {
        margin-top: 7rem;
      }
    }

    &__company {
      margin-bottom: rem(10px);

      &-title {
        font-size: rem(16px);
      }
      &-desc {
        font-size: rem(15px);
        height: initial;
      }
    }

    &__list {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      flex-basis: 90%;
      margin-bottom: 2rem;
    }
  }

  .Achievement {
    .Inner-section {
      width: 93%;

      .section-heading {
        margin-top: 7rem;
      }
    }

    &__list {
      justify-content: center;
    }

    &__item {
      flex-basis: 100%;
      margin-bottom: rem(35px);
    }
  }

  .Slider-cert {
    width: 90%;

    &__item {
      width: 320px;
    }
  }
}