.About {
  font-size: rem(16px);

  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: rem(30px);

    h1 {
      font-size: 3.5rem;
      text-transform: uppercase;
      color: rgba(255, 255, 255, .3);
      font-weight: 300;
      position: relative;
      margin-bottom: 1rem;

      &:after,
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.5rem;
        //background-color: rgba(255,255,255, 0.75);
      }

      &:after {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        bottom: -0.9rem;
        background: linear-gradient(to top, #ffe838, #fe4f6c);
      }

      &:before {
        width: 10rem;
        height: 3px;
        border-radius: 0.8rem;
        background: linear-gradient(to right, #ffe838, #fe4f6c);
      }
    }

    h6 {
      font-size: 2rem;
      font-weight: 300;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -35%);
    }
  }


  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //align-items: center;
    justify-content: space-between;
    //padding: 0 rem(10px);
    width: 80%;
    margin: 0 auto;
    max-width: 120rem;
  }

  &__portrait {
    flex-basis: 50%;
  }

  &__pick {
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 350px;
    //width: 100%;

    &-img {
      position: absolute;
      left: -9999px;
      height: 1px;
      width: 1px;
    }
  }

  &__content {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;

  }

  &__description {
    flex-basis: 100%;
    font-size: rem(18px);
    //width: 75%;
    //text-align: center;
    margin-bottom: rem(50px);
  }

  &__img {
    flex: 1;
    margin-right: 3rem;

    img {
      width: 100%;
    }
  }

  &__details {
    //flex: 1;
  }


  &__skills {
    display: flex;
    flex-direction: column;
    //margin-top: rem(10px);

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: rem(16px);
      color: #fff;
      opacity: 0.3;
      font-weight: 700;
      margin-bottom: rem(20px);
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;
  }

  &__item {
    //margin-bottom: rem(20px);
    display: flex;
    flex-direction: column;
    //align-items: center;

    margin: 0 rem(35px) rem(45px) 0;
    //div {
    //  margin: 0 rem(30px) 0 0;
    //}

    &--right_none {
      margin-right: 0;
    }
  }

  &__type {
    //width: rem(120px);
    opacity: 0.6;
    margin-bottom: rem(20px);
    //text-align: right;
    //margin-right: rem(20px);
  }

  &__list-inner {
    display: flex;
    flex-direction: row;
  }

  &__item-inner {
    margin-right: rem(15px);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: $secondary-color;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: $primary-color;
    font-size: rem(12px);
    opacity: 0.3;
    @include easeOut;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 1;
    }

    &--sass {
      background: #C76595;
    }

    &--html {
      background: #E86335;
    }

    &--css {
      background: #287ABC;
    }

    &--bem {
      background: #3BA1E6;
    }

    &--js {
      background: #F0DA49;
    }

    &--react {
      background: #5FD5F3;
    }

    &--php {
      background: #7478AF;
    }

    &--laravel {
      background: #E74B36;
    }

    &--yii {
      background: #2967B2;
    }

    &--mysql {
      background: #205F87;
    }

    &--jenkins {
      background: #CD3631;
    }

    &--git {
      background: #E74B33;
    }

    &--linux {
      background: #F8F8F8;
    }

    &--nginx {
      background: #43954B;
    }

    &--aws {
      background: #ED953C;
    }

    &--codep {
      background: #739742;
    }
  }
}