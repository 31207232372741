.container {

}

.section {
    height: 100vh;
    overflow: hidden;
    //min-height: rem(650px);
    position: relative;
}

.LoadingPage {
    position: fixed;
    z-index: 333;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3a3973;
}

.overlay_black {
    position: fixed;
    z-index: 333;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: #111;
    opacity: .6;
}

.Modal {
    position: fixed;
    z-index: 100;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 5%;
    height: 90%;
    overflow: auto;
    box-sizing: border-box;
    transition: all 0.3s ease-out;

    &.Modal__register {
        top: 15%;
        height: 75%;
    }
}

@media (min-width: 600px) {
    .Modal {
        width: 800px;
        left: calc(50% - 400px);
    }
}

.text-secondary {
    color: $secondary-color;
}

.scrollbar-cyan::-webkit-scrollbar-track {
    // -webkit-box-shadow: 0 0 0 0;
    background-color: #F5F5F5;
    border-radius: rem(10px); }

.scrollbar-cyan::-webkit-scrollbar {
    width: rem(12px);
    background-color: #F5F5F5; }

.scrollbar-cyan::-webkit-scrollbar-thumb {
    border-radius: rem(10px);
    // -webkit-box-shadow: 0 0 0 0;
    background-color: #ff7204; }

.scrollbar-purple::-webkit-scrollbar-track {
    // -webkit-box-shadow: 0 0 0 0;
    background-color: #F5F5F5;
    border-radius: rem(10px); }

.scrollbar-purple::-webkit-scrollbar {
    width: rem(12px);
    background-color: #F5F5F5; }

.scrollbar-purple::-webkit-scrollbar-thumb {
    border-radius: rem(10px);
    // -webkit-box-shadow: 0 0 0 0;
    background-color: #a6c; }

.square::-webkit-scrollbar-track {
    border-radius: 0 !important; }

.square::-webkit-scrollbar-thumb {
    border-radius: 0 !important; }

.thin::-webkit-scrollbar {
    width: rem(6px); }
