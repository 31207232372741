.Main {
  padding: 4rem;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  //background-image: url("/assets/main/back_fon.jpg");
  //background-repeat: no-repeat;
  //background-position: center;
  //background-size: cover;

  //&::before {
  //  position: absolute;
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //  top: 0;
  //  content: "";
  //  display: block;
  //  background-color: #3a3973;
  //  opacity: .8;
  //}

  &__drop {

  }

  .lg-heading {
    //margin-top: 20vh;

    strong {
      //color: $secondary-color;
      background: linear-gradient(to top, #fe4f6c, #ffe838);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: normal;
    }
  }

  .sm-heading {
    //font-family: 'Montserrat', sans-serif;
  }

  &__description {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }

  .typed-cursor {
    opacity: 0.2 !important;
  }
}