.Modal-Full-Screen {
  position: fixed;
  overflow: auto;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  transform: translateY(-100vh);
  opacity: 0;
  transition: all 0.3s ease-in;
  border: 5px solid #524194;
  //border: 5px solid #b8b8b8;

  box-shadow: 0 0 0 0;
  padding: rem(30px);
  z-index: 1500;
  box-sizing: border-box;

  &__close-element {
    position: absolute;

    top: 3px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    z-index: 100;
  }

  &__icon {
    color: #b8b8b8;
    transition: all .3s ease-out;
    //border: 2px solid #b8b8b8;
    border: 2px solid #524194;
    padding: rem(1px) rem(10px);

    &:hover {
      color: #524194;
      transform: rotate(90deg);
    }
  }

  &--show {
    transform: translateY(0);
    opacity: 1;
  }
}