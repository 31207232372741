.Portfolio {
  padding-bottom: rem(30px);

  &__inner {
    margin-top: rem(50px);
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  &__item {
    display: flex;
    flex-basis: 31%;
    flex-direction: column;
    //padding: 0 5px 0 10px;
    padding: rem(20px);
    border-radius: 5px;
    box-sizing: border-box;
    background: transparent;
    box-shadow: 0 1px 15px rgba(153, 133, 255, 0.2);
  }

  &__company {
    display: flex;
    flex-direction: column;

    &-title {
      text-align: center;
      margin-bottom: rem(20px);
    }

    &-desc {
      line-height: rem(20px);
      font-size: rem(14px);
      height: rem(90px);
    }
  }

  &__line {
    margin: rem(15px) 0;
    padding: 0.2rem 1rem;
    background: rgba(lighten($primary-color, 2), 0.5);
    //box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15) !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;


    text-transform: uppercase;
    font-size: rem(12px);
    color: #fff;
    opacity: 0.3;
    font-weight: 700;
    text-align: center;
  }

  &__tasks {
    &-list {
      display: flex;
      flex-direction: column;
    }

    height: rem(150px);

    &-item {
      margin-bottom: rem(15px);
      font-size: rem(16px);
      line-height: rem(20px);
      position: relative;
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: rem(8px);
        height: rem(8px);
        background: linear-gradient(to top, #ffe838, #fe4f6c);
      }
    }
  }

  &__recommendation {
    display: flex;
    align-items: center;
    justify-content: center;

    &-logo {
      width: 100px;
      height: 140px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      cursor: pointer;
      border: 2px solid #fe4f6c;

      &:hover {
        svg {
          font-size: rem(35px);
        }
      }
    }

    &-icon {
      color: #ffe838;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      svg {
        font-size: rem(25px);
        transition: all 0.2s ease-out;
      }
    }

    &-pick {
      position: absolute;
      left: -9999px;
    }
  }

  &__modal {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/assets/spinner_2.gif");
    background-size: 30px 30px;


    &-pick {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    &-img {
      position: absolute;
      left: -9999px;
      height: 1px;
      width: 1px;
    }
  }

}